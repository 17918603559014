<template>
    <!-- 商品详情 -->
    <div class="product-detail main-content-wrap">
        <el-breadcrumb class="nav-list" separator="/">
            <template v-for="(bread, index) in breadcrumbs">
                <el-breadcrumb-item v-if="index !== breadcrumbs.length - 1"
                    :key="index"
                    :to="bread.to">
                    {{ bread.name || '' }}
                </el-breadcrumb-item>
                <el-breadcrumb-item v-else
                    :key="index">
                    {{ bread.name || '' }}
                </el-breadcrumb-item>
            </template>
        </el-breadcrumb>
        <!-- 商品详情 -->
        <product-info :product.sync="product"></product-info>
    </div>
</template>

<script>
import ProductInfo from './components/product-info'

export default {
    components: {
        ProductInfo
    },
    data () {
        return {
            product: {}
        }
    },
    computed: {
        breadcrumbs () {
            return this.$store.state.productCenter.productBreadcrumbs || []
        }
    }
}
</script>

<style lang="scss" scoped>
.product-detail{
    .nav-list{
        padding: 12px 0 18px 0;
        .el-breadcrumb__item {
            /deep/ .el-breadcrumb__inner{
                color: #808080;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
            }
            &:last-of-type /deep/ .el-breadcrumb__inner a{
                color: $mainPrimary;
                max-width: 120px;
                @include ellipsis()
            }
        }
    }
}
</style>
