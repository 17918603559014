<template>
    <div class="evaluation-wrap border-top">
        <div class="anchor" id="EvaluationAnchorId"></div>
        <div class="box">
            <div class="module-title">{{ title }}</div>
            <div class="comment-data">
                <div class="left">
                    <div class="star-wrap">
                        <rap-rate v-model="commentData.star"
                            disabled
                            show-score
                            text-color="#ff9900">
                        </rap-rate>
                        <span class="review">{{ commentData.commentNum || 0 }} Reviews</span>
                    </div>
                    <div class="label-process">
                        <el-row :gutter="10">
                            <el-col :span="10">
                                <div class="process-item">
                                    <div class="label">Value for Money</div>
                                    <el-progress color="#FFC017" :stroke-width="10" :show-text="false" :percentage="commentData.starValueForMoney"></el-progress>
                                </div>
                            </el-col>
                            <el-col :span="10">
                                <div class="process-item">
                                    <div class="label">Product Quality</div>
                                    <el-progress color="#FFC017" :stroke-width="10" :show-text="false" :percentage="commentData.starProductQuality"></el-progress>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="10">
                                <div class="process-item">
                                    <div class="label">True to Description</div>
                                    <el-progress color="#FFC017" :stroke-width="10" :show-text="false" :percentage="commentData.starTrueToDescription"></el-progress>
                                </div>
                            </el-col>
                            <el-col :span="10">
                                <div class="process-item">
                                    <div class="label">Shipping</div>
                                    <el-progress color="#FFC017" :stroke-width="10" :show-text="false" :percentage="commentData.starShipping"></el-progress>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="btns">
                    <el-button
                        class="preview"
                        type="primary"
                        @click="handleWriteReview">
                        Write a review
                    </el-button>
                </div>
            </div>
            <div class="select-wrap">
                <div class="item-wrap">
                    <label>Rating</label>
                    <rap-select v-model="search.star"
                        :lists="ratings"
                        @change="doSearch">
                    </rap-select>
                </div>
                <div class="item-wrap">
                    <label>Sort by</label>
                    <rap-select v-model="search.starSort"
                        :lists="sorts"
                        @change="doSearch">
                    </rap-select>
                </div>
            </div>
        </div>
        <!-- 评论列表 -->
        <comment-list ref="comment" :search="search"></comment-list>
    </div>
</template>

<script>
import CommentList from './components/comment-list'
export default {
    props: ['product', 'title'],
    components: {
        CommentList
    },
    data () {
        return {
            ratings: [
                {
                    label: 'ALL Star',
                    value: ''
                },
                {
                    label: '1 Star',
                    value: 1
                },
                {
                    label: '2 Star',
                    value: 2
                },
                {
                    label: '3 Star',
                    value: 3
                },
                {
                    label: '4 Star',
                    value: 5
                },
                {
                    label: '5 Star',
                    value: 5
                }
            ],
            sorts: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'ASC',
                    value: 0
                },
                {
                    label: 'DES',
                    value: 1
                }
            ],
            search: {
                star: '',
                starSort: ''
            }
        }
    },
    computed: {
        commentData () {
            const data = (this.product.skuDto && this.product.skuDto.commentCount) || {}
            var starProductQuality = (data.starProductQuality * 100) / 5 || 0
            var starShipping = (data.starShipping * 100) / 5 || 0
            var starTrueToDescription = (data.starTrueToDescription * 100) / 5 || 0
            var starValueForMoney = (data.starValueForMoney * 100) / 5 || 0
            return {
                commentNum: data.commentNum || 0,
                star: data.star || 0,
                starProductQuality,
                starShipping,
                starTrueToDescription,
                starValueForMoney
            }
        }
    },
    methods: {
        doSearch () {
            this.$refs.comment &&
            this.$refs.comment.reqCommentList()
        },
        handleWriteReview () {
            this.$router.push({
                path: `/product/addComment/${this.product.skuDto.skuId}/${this.product.skuDto.spuId}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.evaluation-wrap{
    padding: 24px 0 0 0;
    position: relative;
    .box{
        padding: 0 24px;
    }
    .comment-data{
        margin-top: 24px;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 24px;
        background: #f7f8fa;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-right: 100px;
            .star-wrap{
                margin-bottom: 12px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;
                /deep/ .el-rate{
                    height: auto;
                }
                /deep/ .el-rate__icon {
                    font-size: 38px;
                    margin-right: 5px;
                }
                /deep/ .el-rate__text {
                    font-size: 27px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #000000 !important;
                    margin-left: 25px;
                }
                .review{
                    color: $mainPrimary;
                    font-size: 20px;
                    margin-left: 25px;
                    line-height: 30px;
                }
            }
            .label-process {
                width: 100%;
                display: flex;
                flex-direction: column;
                .el-row{
                    margin: 12px 0;
                }
                .process-item{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .label{
                        display: inline-block;
                        padding: 0 10px;
                        width: 146px;
                        font-size: 13px;
                        font-family: PingFangSC, PingFangSC-Medium;
                        font-weight: 500;
                        text-align: right;
                        flex-shrink: 0;
                    }
                    .el-progress{
                        width: 200px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .btns {
            flex-shrink: 0;
            .preview{
                width: 170px;
                height: 50px;
                text-align: center;
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #ffffff;
            }
        }
    }
    .select-wrap{
        padding: 17px 0 24px 0;
        display: flex;
        flex-direction: row;
        .item-wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-right: 20px;
            label {
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #1a1a1a;
                margin-right: 10px;
            }
            /deep/ .rap-select{
                width: 120px;
            }
        }
    }
}
</style>
