<template>
    <div class="shipping-wrap border-top">
        <div class="anchor" id="WarehouseAnchorId"></div>
        <div class="module-title">{{ title }}</div>
        <div class="data-list">
            <div class="data-item" v-for="(item, index) in dataList" :key="index">
                <el-row :gutter="10" class="header">
                    <el-col :span="7">Warehouse name</el-col>
                    <el-col :span="10">Warehouse Address</el-col>
                    <el-col :span="7">Shipping method</el-col>
                </el-row>
                <el-row :gutter="10" class="content">
                    <el-col :span="7">{{ item.warehouseName }}</el-col>
                    <el-col :span="10">{{ getAddress(item) }}</el-col>
                    <el-col :span="7">{{ `${item.shippingType}` === '0' ? 'Dropshipping' : (`${item.shippingType}` === '1' ? 'Wholesale' : '') }}</el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['product', 'title'],
    data () {
        return {}
    },
    computed: {
        dataList () {
            return (this.product && this.product.skuDto && this.product.skuDto.pmsShippingDeliveries) || []
        }
    },
    methods: {
        getAddress (item) {
            const keys = ['detailAddress', 'city', 'province', 'postCode', 'countryCode']
            const address = []
            keys.map(key => {
                if (item[key]) {
                    address.push(item[key])
                }
            })
            return address.join(',')
        }
    }
}
</script>

<style lang="scss" scoped>
.shipping-wrap{
    padding: 24px 24px 0 24px;
    position: relative;
    .data-list {
        padding: 24px 0;
        .data-item {
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 2px;
            padding: 20px;
            .header {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0,0,0,0.65);
            }
            .el-row {
                &:not(:last-of-type) {
                    margin-bottom: 12px;
                }
                .el-col {
                    height: 25px;
                    line-height: 25px;
                }
            }
            .content {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #1a1a1a;
            }
        }
    }
}
</style>
