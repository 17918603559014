<template>
    <div class="product-modules__wrap">
        <div class="product-modules__index">
            <!-- 模块切换 -->
            <el-radio-group class="btn-group"
                v-model="active"
                @change="handleRadioChange">
                <el-radio-button v-for="(btn, index) in buttons"
                    :key="index"
                    :label="btn.pointId"
                    border>
                    {{ btn.label }}
                </el-radio-button>
            </el-radio-group>
            <!-- 统计 -->
            <template v-for="(btn, index) in buttons">
                <component :is="btn.component"
                    :key="index"
                    :title="btn.label"
                    :product="product">
                </component>
            </template>
        </div>
    </div>
</template>

<script>
import StatisticModule from './modules/statistic'
import VariantsModule from './modules/variant'
import ShippingModule from './modules/shipping'
import ReturnNoticeModule from './modules/returnNotice'
import EvaluationModule from './modules/evaluation'
import WarehouseModule from './modules/warehouse'

export default {
    props: ['product', 'btns', 'tabActive'],
    components: {
        StatisticModule,
        VariantsModule,
        ShippingModule,
        ReturnNoticeModule,
        EvaluationModule,
        WarehouseModule
    },
    data () {
        return {
            buttons: [
                {
                    label: 'Product statistics',
                    pointId: 'Statistics',
                    component: StatisticModule
                },
                {
                    label: 'Variants',
                    pointId: 'Variants',
                    component: VariantsModule
                },
                {
                    label: 'Warehouse Address',
                    pointId: 'Warehouse',
                    component: WarehouseModule
                },
                {
                    label: 'Return & Notice',
                    pointId: 'ReturnNotice',
                    component: ReturnNoticeModule
                },
                {
                    label: 'Evaluation',
                    pointId: 'Evaluation',
                    component: EvaluationModule
                }
            ],
            active: 'Statistics',
            showFixedProduct: false
        }
    },
    watch: {
        tabActive: {
            handler (val) {
                this.active = val
            },
            immediate: true
        }
    },
    methods: {
        // 切换tab
        handleRadioChange (pointId) {
            this.$emit('update:tabActive', this.active)
            if (pointId) {
                document.getElementById(`${pointId}AnchorId`).scrollIntoView()
            }
        }
    },
    mounted () {
        this.$emit('update:btns', this.buttons)
        this.$emit('update:tabActive', this.active)
    }
}
</script>

<style lang="scss">
.product-modules__wrap{
    margin: 24px 0;
    padding: 20px 0 0 0;
    background: white;
    border: 1px solid $borderColor;
    .anchor{
        position: absolute;
        top: calc(-150px - #{$headerHeight});
        z-index: -1;
    }
    .btn-group{
        padding: 0 24px;
    }
    /deep/ .el-radio-button__inner {
        font-size: 14px;
        font-family: Helvetica;
    }
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner{
        color: $mainPrimary;
        background-color: #fff2eb;
        border-color: $mainPrimary;
        box-shadow: -1px 0 0 0 $mainPrimary;
    }
    .border-top{
        border-top: 1px solid $borderColor;
    }
    .module-title{
        font-size: 26px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
        line-height: 37px;
    }
}
</style>
