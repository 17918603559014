<template>
    <div class="comment-list__wrap" v-if="commentLists.length" v-loading="loading">
        <div class="comment-list border-top">
            <div class="comment-item"
                v-for="(com, index) in commentLists"
                :key="index">
                <div class="content-wrap">
                    <div class="star-title-wrap">
                        <rap-rate v-model="com.star"
                            disabled>
                        </rap-rate>
                        <div class="title">{{ com.title }}</div>
                    </div>
                    <div class="content">
                        {{ com.content }}
                    </div>
                </div>
                <div class="date-name">
                    <span class="date">{{ $monent.format(com.createTime) }}</span>
                    <span class="name">{{ com.nickName }}</span>
                </div>
            </div>
        </div>
        <rap-pagination :total="total" :currentPage="pageNum" :pageSize="pageSize" @change="pageChange"></rap-pagination>
    </div>
</template>

<script>
export default {
    props: ['search'],
    data () {
        return {
            commentLists: [],
            total: 0,
            pageNum: 1,
            pageSize: 20,
            loading: false
        }
    },
    created () {
        this.reqCommentList()
    },
    methods: {
        // 请求评论列表
        reqCommentList () {
            this.loading = true
            this.$api.getCommentLists({
                limit: this.pageSize,
                pageNo: this.pageNum,
                skuId: this.$route.params && this.$route.params.skuId,
                starSort: this.search.starSort || '',
                star: this.search.star || ''
            }).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.commentLists = (res.data && res.data.rows) || []
                    this.total = (res.data && res.data.totalPage) || 0
                }
            }).catch(() => {
                this.loading = false
            })
        },
        pageChange (data) {
            this.pageNum = data.pageNum
            this.pageSize = data.pageSize
            this.reqCommentList()
        }
    }
}
</script>

<style lang="scss" scoped>
.comment-list__wrap{
    .comment-list{
        padding: 0 48px 0 28px;
        .comment-item{
            padding: 24px 0;
            border-bottom: 1px dashed $borderColor;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            .content-wrap {
                width: 60%;
                flex-shrink: 0;
                .star-title-wrap{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 16px;
                    .el-rate{
                        margin-right: 24px;
                        height: auto;
                    }
                    /deep/ .el-rate__icon {
                        font-size: 24px;
                        margin-right: 5px;
                    }
                    /deep/ .el-rate__text {
                        margin-left: 15px;
                        font-family: PingFangSC, PingFangSC-Medium;
                        font-weight: 500;
                    }
                    .title{
                        font-size: 18px;
                        font-family: PingFangSC, PingFangSC-Medium;
                        font-weight: 500;
                        color: #1a1a1a;
                        @include ellipsis();
                    }
                }
                .content{
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
            }
            .date-name {
                min-width: 160px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-shrink: 0;
                font-family: PingFangSC, PingFangSC-Regular;
                color: #4d4d4d;
                text-align: right;
                font-weight: 400;
                font-size: 12px;
                .date {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
