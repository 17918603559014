/* eslint-disable*/
Set.prototype._log = function (){
    var str = ''
    this.forEach(e=>{
        str += (JSON.stringify(e) + '\n')
    })
}

Set.prototype._empty = function (){
    var count = 0
    this.forEach(e=>{
        count++
    })
    return count == 0
}

Set.prototype._length = function (){
    var count = 0
    this.forEach(e=>{
        count++
    })
    return count
}

Set.prototype._findIndex = function (index){
    var arr = Array.from(this)
    var obj
    arr.forEach((e, i)=>{
        if(i == index){
            obj = e
        }
    })
    return obj
}

export default {
    data () {
        return {
            marryConditions: [], // 匹配条件数组 specCombinationList里面的条件
            marryItems: [], // 匹配项 所有顶点集合
            set: null, // 可选顶点集合
            alreadys: null, // 放 已经选中了的顶点集合
            skuProduct: false
        }
    },
    created () {
        this.set = new Set()
        this.alreadys = new Set()
    },
    methods: {
        //转换数据 设置坐标
        initData() {
            this.marryItems = []
            this.productAttrInfo.attrGroup.map((spec, index) => {
                spec.attrValue = spec.attrValue.map((t, i) => {
                    var tmp = {
                        title: '',
                        point: {
                            x: 0,
                            y: index
                        }
                    }
                    tmp.point.x = i
                    tmp.title = t
                    tmp.key = spec.attrName
                    this.marryItems[t] = tmp.point
                    return tmp
                })
            })
            //取x, 用y取匹配( 取y用x好像无法匹配 )
            let lists = []
            this.marryConditions = []
            this.productAttrInfo.skuAttrGroup.map(spec => {
                lists = []
                if (!spec.skuAttrs) {
                    spec.skuAttrs = []
                }
                spec.skuAttrs.map(e => {
                    if (this.marryItems.hasOwnProperty(e.attrValue)) {
                        lists.push(this.marryItems[e.attrValue].x)
                    }
                })
                this.marryConditions.push(lists)
            })
            this.handleChooseAttr()
        },

        handleChooseAttr(item, index) {
            // 选中的顶点不在可选顶点中，不可选
            if (item && !(this.set && this.set._empty())) {
                if (!this.set.has(item.title)) {
                    console.log('不可选')
                    return
                }
            }
            //判断是否有相同类目的顶点
            if (item) {
                let sameTop = this.hasSameTop(item)
                if(sameTop){
                    //有相同类目的顶点
                    this.deleteSameTop(item)
                }else{
                    // 没有相同类目的顶点直接加入集合
                    this.alreadys.add(item)
                }
            }
            this.alreadys._log()
            this.set.clear()
            
            //迭代所有的条件， 同时满足alreadys里面的顶点的交集
            var tmpSet = new Set()
            this.marryConditions.forEach((arr, index) => {
                var b = true
                this.alreadys.forEach(already => {
                    if (arr[already.point.y] != already.point.x) {
                        b = false
                    }
                })
                if (b) {
                    let tmpSet = new Set(this.productAttrInfo.skuAttrGroup[index].skuAttrs.map(p => p.attrValue))
                    this.set = new Set([...this.set, ...tmpSet])
                    tmpSet = new Set([...this.set, ...tmpSet])
                }
            })
            //判断如果只有一个类目的顶点， 要把该类目的所有顶点都加到集合中
            if (this.alreadys._length() == 1) {
                let tmpItem = this.alreadys._findIndex(0)
                this.productAttrInfo.attrGroup[tmpItem.point.y].attrValue.forEach(e=>{
                    this.set.add(e.title)
                })
            }
            //是否选择完成
            if (this.alreadys._length() == this.productAttrInfo.attrGroup.length) {
                //选择完成， 获取剩余数量显示出来
                this.calculateSellCount()
            }
        },
        
        //是否有相同的顶点
        hasSameTop(item){
            var flag = false
            this.alreadys.forEach(e=>{
                if(e.point.y == item.point.y){
                    flag = true
                }
            })
            return flag
        },
        
        deleteSameTop(item){
            //如果有相同类目的顶点
            if(this.alreadys.has(item)){
                //选择的同一个顶点
                this.alreadys.delete(item)
            }else{
                //选择同一个类目不同的顶点， 先清空集合内同一个类目的顶点， 在加入选中的顶点
                this.alreadys.forEach(e=>{
                    if(e.point.y == item.point.y){
                        this.alreadys.delete(e)
                    }
                })
                this.alreadys.add(item)
            }
        },

        calculateSellCount() {
            const params = this.$route.params
            this.marryConditions.forEach((arr, index) => {
                var b = true
                this.alreadys.forEach(already => {
                    if (arr[already.point.y] != already.point.x) {
                        b = false
                    }
                })
                if (b) {
                    const skuId = this.productAttrInfo.skuAttrGroup[index].skuId
                    if (`${skuId}` !== `${params.skuId}`) {
                        this.skuProduct = true
                        this.$router.push({
                            path: `/product/detail/${skuId}/${params.spuId}`
                        })
                    }
                }
            })
        },

        alreadysTitle() {
            var set = new Set()
            if (this.alreadys) {
                this.alreadys.forEach(e => {
                    set.add(e.title)
                })
            }
            return set
        },

        getItem(item) {
            let title = item.title
            var c = 0;
            if (this.alreadysTitle().has(title)) {
                c = 1
            } else {
                if (this.set && this.set._empty()) {
                    c = 0
                } else if (this.set && this.set.has(title)) {
                    c = 0
                } else {
                    c = 2
                }
            }
            return c
        }
    },
}