<template>
    <div class="return-notice-wrap border-top">
        <div class="anchor" id="ReturnNoticeAnchorId"></div>
        <div class="module-title">{{ title }}</div>
        <div class="content" v-html="returnNotice"></div>
    </div>
</template>

<script>
export default {
    props: ['product', 'title'],
    data () {
        return {}
    },
    computed: {
        returnNotice () {
            return (this.product && this.product.skuDto && this.product.skuDto.returnNotice) || ''
        }
    }
}
</script>

<style lang="scss" scoped>
.return-notice-wrap{
    padding: 24px 24px 0 24px;
    position: relative;
    .content {
        padding: 24px 0;
        width: 100%;
    }
}
</style>
