<template>
    <div class="statistic-wrap">
        <div class="anchor" id="StatisticsAnchorId"></div>
        <div class="module-title">{{ title }}</div>
        <template v-for="(item, index) in dataLists">
            <div class="table-box" :key="index">
                <p class="title-qty">Sub-item {{ index + 1 }} | Package Quantity: {{ item.num }}</p>
                <div class="table">
                    <el-row :gutter="0">
                        <el-col :span="7">
                            <div class="cell">length<span class="desc">(inches)</span></div>
                        </el-col>
                        <el-col :span="7">
                            <div class="cell">width<span class="desc">(inches)</span></div>
                        </el-col>
                        <el-col :span="7">
                            <div class="cell">height<span class="desc">(inches)</span></div>
                        </el-col>
                        <el-col :span="3">
                            <div class="cell">weight<span class="desc">(inches)</span></div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="0">
                        <el-col :span="7">
                            <div class="cell">{{ item.sLong }}</div>
                        </el-col>
                        <el-col :span="7">
                            <div class="cell">{{ item.sWidth }}</div>
                        </el-col>
                        <el-col :span="7">
                            <div class="cell">{{ item.sHeight }}</div>
                        </el-col>
                        <el-col :span="3">
                            <div class="cell">{{ item.sWeight }}</div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </template>
        <div class="desciption" v-html="desciption"></div>
    </div>
</template>

<script>
export default {
    props: ['product', 'title'],
    data () {
        return {}
    },
    computed: {
        dataLists () {
            return (this.product && this.product.skuDto && this.product.skuDto.wmsSkus) || []
        },
        desciption () {
            return (this.product && this.product.skuDto && this.product.skuDto.descript) || ''
        }
    }
}
</script>

<style lang="scss" scoped>
.statistic-wrap{
    padding: 24px 24px 0 24px;
    position: relative;
    .table-box{
        margin-top: 24px;
        .title-qty{
            margin-bottom: 10px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #1a1a1a;
        }
        .table{
            border-top: 1px solid $borderColor;
            border-left: 1px solid $borderColor;
            .cell{
                height: 46px;
                line-height: 46px;
                padding: 0 24px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 550;
                color: #1a1a1a;
                border-bottom: 1px solid $borderColor;
                border-right: 1px solid $borderColor;
                .desc{
                    color: #4D4D4D;
                    font-weight: 500;
                }
            }
        }
    }
    .desciption{
        padding: 28px 0 40px 0;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #1a1a1a;
        line-height: 22px;
    }
}
</style>
