<template>
    <div class="shipping-wrap border-top">
        <div class="anchor" id="ShippingAnchorId"></div>
        <div class="module-title">{{ title }}</div>
    </div>
</template>

<script>
export default {
    props: ['product', 'title'],
    data () {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.shipping-wrap{
    padding: 24px 24px 0 24px;
    position: relative;
}
</style>
