<template>
    <div class="product-info__index">
        <!-- 商品详情 -->
        <div class="product-info__wrap">
            <div class="product-images">
                <div class="image-list">
                    <el-scrollbar style="height: 100%;width: 100%;" v-if="productDetail.images && productDetail.images.length">
                        <section v-for="(img, index) in productDetail.images"
                            :key="index"
                            :class="['image-card', { 'sel': defaultImage === img.imgUrl }]"
                            @click="handleImageOver(img)">
                            <rap-image :src="img.imgUrl"></rap-image>
                        </section>
                    </el-scrollbar>
                    <el-scrollbar style="height: 100%;width: 100%;" v-else>
                        <section v-for="(n, index) in 4"
                            :key="index"
                            class="image-card">
                        </section>
                    </el-scrollbar>
                </div>
                <div class="preview-image">
                    <transition name="el-fade-in">
                        <rap-image v-if="defaultImage" class="image" fit="fill" :src="defaultImage" :loading="true"></rap-image>
                    </transition>
                </div>
            </div>
            <div class="product-info">
                <div class="product-name">
                    {{ productDetail.sellSkuName }}
                </div>
                <!-- 星级 -->
                <div class="star-number">
                    <rap-rate v-model="productDetail.star"
                        disabled
                        show-score>
                    </rap-rate>
                    <div class="review-num">
                        {{ productDetail.commentNum }} Reviews
                    </div>
                </div>
                <!-- 仓库标签 -->
                <div class="tag-wrap">
                    <el-tag v-for="tag in skuTags"
                        :key="tag"
                        type="success">
                        {{tag}}
                    </el-tag>
                </div>
                <!-- 供应商名称 -->
                <div class="supplier-name">
                    Supplier：{{ productDetail.supplierName }}
                </div>
                <!-- 商品价格 -->
                <div class="label-wrap price">
                    <label class="title">Price</label>
                    <div class="price-box">
                        <div class="sell"
                            v-if="productDetail.sellPriceString">
                            {{ productDetail.originCurrency | filterCurrent }} {{ productDetail.sellPriceString }}
                        </div>
                        <div class="origin"
                            v-if="productDetail.originPriceString">
                            {{ productDetail.originCurrency | filterCurrent }} {{ productDetail.originPriceString }}
                        </div>
                    </div>
                </div>
                <!-- 商品规格 -->
                <template v-for="(attr, index) in productAttrInfo.attrGroup">
                    <div class="label-wrap" :key="index">
                        <label class="title">{{ attr.attrName }}</label>
                        <div class="size-box" v-if="attr.attrValue && attr.attrValue.length">
                            <el-button size="small" v-for="(item, sIndex) in attr.attrValue" :key="sIndex"
                                :class="{'sel': getItem(item) === 1 }"
                                :disabled="getItem(item) === 2"
                                @click="handleChooseAttr(item, index)">
                                {{ item.title }}
                            </el-button>
                        </div>
                    </div>
                </template>
                <!-- shipping -->
                <div class="shipping-wrap">
                    <label class="title">Shipping</label>
                    <el-popover
                        placement="bottom-start"
                        width="300"
                        trigger="hover"
                        popper-class="shipping-free">
                        <div slot="reference" class="shipping-content">
                            <span>Shipping Fee
                                {{ productDetail.originCurrency | filterCurrent }}
                                {{ (productDetail.shippingFeeFlag !== 0 && productDetail.unitPackagingPriceString) || '0.00' }}，
                                Packaging Fee
                                {{ productDetail.originCurrency | filterCurrent }}
                                {{ (productDetail.shippingFeeFlag !== 0 && productDetail.unitShippingPriceString) || '0.00' }}
                            </span>
                            <i class="el-icon-question"></i>
                        </div>
                        <div class="free-content">
                            <div class="free">Shipping Fee <span>{{ productDetail.originCurrency | filterCurrent }}{{ (productDetail.shippingFeeFlag !== 0 && productDetail.unitPackagingPriceString) || '0.00' }}</span></div>
                            <div class="free last">Packaging Fee<span>{{ productDetail.originCurrency | filterCurrent }}{{ (productDetail.shippingFeeFlag !== 0 && productDetail.unitShippingPriceString) || '0.00' }}</span></div>
                            <div class="desc" v-if="productDetail.shippingFeeFlag !== 0">
                                Orders will be shipped from our warehouses within 2-5 business days after the order has been placed. Estimated delivery timeframes are between 3-15 business days after shipping from our warehouses.
                            </div>
                        </div>
                    </el-popover>
                </div>
                <!-- 按钮 -->
                <div class="btn-group">
                    <el-button v-if="importStatus === 0" size="medium"
                        type="primary"
                        :loading="loading"
                        @click="importShopify">
                        Add to import list
                    </el-button>
                    <el-button v-if="importStatus !== 0" size="medium"
                        :loading="loading"
                        @click="removeShopify">
                        {{ product.pushStatus === 2 ? 'Remove from My Product' : 'Remove from import list' }}
                    </el-button>
                    <el-button size="medium" type="info" class="add" :loading="isAdding" @click="doAddCart">Add to Cart</el-button>
                </div>
            </div>
        </div>
        <!-- 商品介绍 -->
        <product-modules :product="product" :btns.sync="buttons" :tabActive.sync="tabActive"></product-modules>
        <!-- fixed 商品详情 -->
        <transition name="el-zoom-in-top">
            <div class="fixed-product-info t-fixed" v-if="showFixedProduct && productDetail">
                <div class="main-content-wrap fixed-product">
                    <div class="left-wrap">
                        <div class="info-wrap">
                            <rap-image class="image" fit="fill" :src="defaultImage" :loading="true"></rap-image>
                            <div class="info">
                                <div class="name">{{ productDetail.sellSkuName || '' }}</div>
                                <div class="star-number">
                                    <rap-rate v-model="productDetail.star"
                                        disabled
                                        show-score>
                                    </rap-rate>
                                    <div class="review-num">
                                        {{ productDetail.commentNum || '0' }} Reviews
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-radio-group class="btn-group"
                            v-model="tabActive"
                            @change="handleRadioChange">
                            <el-radio-button v-for="(btn, index) in buttons"
                                :key="index"
                                :label="btn.pointId"
                                border>
                                {{ btn.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="right-wrap">
                        <div class="price-wrap">
                            <span class="title">Price</span>
                            <span class="price">{{ productDetail.originCurrency | filterCurrent }} {{ productDetail.sellPriceString }}</span>
                        </div>
                        <div class="btn-group">
                            <el-button v-if="importStatus === 0" size="medium"
                                type="primary"
                                :loading="loading"
                                @click="importShopify">
                                Add to import list
                            </el-button>
                            <el-button v-if="importStatus !== 0" size="medium"
                                :loading="loading"
                                @click="removeShopify">
                                {{ product.pushStatus === 2 ? 'Remove from My Product' : 'Remove from import list' }}
                            </el-button>
                            <el-button size="medium" type="info" class="add" :loading="isAdding" @click="doAddCart">Add to Cart</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ProductModules from './product-modules'
import ProductMixins from './product.js'
export default {
    props: ['product'],
    mixins: [ProductMixins],
    components: {
        ProductModules
    },
    data () {
        return {
            defaultImage: '',
            ruleForm: {},
            loading: false,
            productDetail: {},
            productAttrInfo: {},
            buttons: [],
            tabActive: '',
            // demo
            selectArr: [],
            isloading: false,
            isAdding: false,
            originProductInfo: null,
            showFixedProduct: false,
            importStatus: 0
        }
    },
    watch: {
        $route: {
            handler (val) {
                const { params } = val
                if (params.skuId) {
                    let spuId = params.spuId
                    if (this.skuProduct) {
                        spuId = ''
                    }
                    this.reqProductDetail(params.skuId, spuId)
                }
            },
            immediate: true
        }
    },
    computed: {
        skuTags () {
            const skuTags = (this.productDetail && this.productDetail.skuTags) || ''
            return skuTags.split(',') || []
        }
    },
    methods: {
        // 请求详情接口
        reqProductDetail (skuId, spuId) {
            this.isloading = true
            this.$api.getProductInfo({
                skuId,
                spuId
            }).then(res => {
                this.isloading = false
                if (res.code === 0 && res.data) {
                    const data = res.data || {}
                    if (data.skuDto) {
                        this.productDetail = data.skuDto
                    }
                    if (data.importStatus) {
                        this.importStatus = data.importStatus || 0
                    }
                    if (data.attrInfo) {
                        this.productAttrInfo = data.attrInfo
                        this.initData()
                    }
                    // 默认图片
                    this.defaultImage = ''
                    if (this.productDetail.images && this.productDetail.images.length) {
                        const defImage = this.productDetail.images[0]
                        this.$nextTick(() => {
                            this.defaultImage = (defImage && defImage.imgUrl) || ''
                        })
                    }
                    this.$emit('update:product', data)
                }
            }).catch(() => {
                this.isloading = false
            })
        },
        // 预览图片
        handleImageOver (img) {
            if (this.defaultImage === img.imgUrl) {
                return ''
            }
            this.defaultImage = ''
            this.$nextTick(() => {
                this.defaultImage = img.imgUrl
            })
        },
        // 从shopify移除
        removeShopify () {
            this.loading = true
            const key = this.product.pushStatus === 2 ? 'removeMyProducts' : 'removeShopifyList'
            this.$api[key]({
                spuId: [this.product.spuId]
            }).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.$notify.success('Remove Success')
                    this.$set(this, 'importStatus', 0)
                    this.$set(this, 'pushStatus', 0)
                }
            }).catch(() => {
                this.loading = false
            })
        },
        // 导入shopify
        importShopify () {
            this.loading = true
            this.$api.importShopifyList({
                spuId: [this.productDetail.spuId]
            }).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.$notify.success('Import Success')
                    this.$set(this, 'importStatus', 1)
                }
            }).catch(() => {
                this.loading = false
            })
        },
        scroll (e) {
            console.log(e.currentTarget.scrollTop)
            if (e.currentTarget.scrollTop > 300) {
                this.showFixedProduct = true
            } else {
                this.showFixedProduct = false
            }
        },
        // 切换tab
        handleRadioChange (pointId) {
            if (pointId) {
                document.getElementById(`${pointId}AnchorId`).scrollIntoView()
            }
        },
        // 添加购物车
        doAddCart () {
            this.isAdding = true
            debugger
            this.$api.addCart({
                skuId: this.productDetail.skuId
            }).then(res => {
                this.isAdding = false
                if (res.code === 0) {
                    this.$notify({
                        type: 'success',
                        title: 'Add shopping cart Success!'
                    })
                }
            }).catch(() => {
                this.isAdding = false
            })
        }
    },
    mounted () {
        this.$bus.$on('el-scrollbar-scroll', this.scroll)
    },
    destroyed () {
        this.$bus.$off('el-scrollbar-scroll', this.scroll)
    }
}
</script>

<style lang="scss">
.product-info__wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .product-images{
        background: white;
        box-sizing: border-box;
        border: 1px solid $borderColor;
        width: 674px;
        margin-right: 24px;
        flex-shrink: 0;
        padding: 24px;
        display: flex;
        flex-direction: row;
        .image-list{
            height: 100%;
            width: 120px;
            flex-shrink: 0;
            display: flex;
            max-height: 550px;
            margin-right: 15px;
            flex-direction: column;
            box-sizing: border-box;
            /deep/ .el-scrollbar__wrap{
                overflow-x: hidden;
            }
            /deep/ .el-scrollbar__thumb {
                display: none;
            }
            .image-card{
                height: 110px;
                width: 110px;
                // margin-right: 16px;
                border: 2px solid white;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 2px;
                cursor: pointer;
                transition: all .15s;
                &:not(:last-of-type){
                    margin-bottom: 10px;
                }
                &:hover{
                    transition: all .2s ease-in-out;
                }
                &.sel{
                    position: relative;
                    border-color: $mainPrimary;
                    transition: all .3s ease-in-out;
                    // &::before {
                    //     content: '';
                    //     position: absolute;
                    //     top: 0;
                    //     bottom: 0;
                    //     right: -10px;
                    //     margin: auto;
                    //     z-index: 99;
                    //     width:0;
                    //     height:0;
                    //     border-top: 10px solid transparent;
                    //     border-bottom: 10px solid transparent;
                    //     border-left: 10px solid $mainPrimary;
                    // }
                }
            }
        }
        .preview-image{
            width: 100%;
            height: 550px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .product-info{
        width: 602px;
        padding: 24px;
        background: white;
        box-sizing: border-box;
        border: 1px solid $borderColor;
        .product-name{
            margin-bottom: 10px;
            font-size: 20px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            color: #1a1a1a;
            @include ellipsis(2);
        }
        .star-number{
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .el-rate{
                margin-right: 15px;
                height: auto;
            }
            /deep/ .el-rate__icon {
                font-size: 20px;
                margin-right: 5px;
            }
            /deep/ .el-rate__text {
                margin-left: 15px;
            }
            .review-num{
                color: $mainPrimary;
                font-size: 12px;
            }
        }
        .supplier-name{
            margin-bottom: 12px;
            opacity: 0.7;
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
        }
        .tag-wrap {
            margin-bottom: 13px;
            /deep/ .el-tag{
                height: 24px;
                line-height: 22px;
                color: #30AD5D;
                background: rgba($color: #30ad5d, $alpha: 0.1);
                margin-right: 12px;
            }
        }
        .title{
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #000000;
            margin-right: 5px;
            line-height: 30px;
            min-width: 65px;
        }
        .label-wrap{
            margin-bottom: 14px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            .price-box{
                display: flex;
                flex-direction: row;
                line-height: 30px;
                .sell {
                    font-size: 22px;
                    color: red;
                    margin-right: 30px;
                }
                .origin {
                    font-size: 18px;
                    text-decoration: line-through;
                    color: #666666;
                }
            }
            .size-box{
                .el-button{
                    margin: 0 10px 10px 0;
                    min-width: 70px;
                    transition: all .3s;
                    &:focus{
                        background: #fff;
                        border-color: #DCDFE6;
                        color: #606266;
                        transition: all .3s;
                    }
                    &.sel{
                        color: $mainPrimary;
                        background: rgba($color: $mainPrimary, $alpha: 0.1);
                        border-color: rgba($color: $mainPrimary, $alpha: 0.3);
                        transition: all .3s;
                    }
                }
            }
        }
        .shipping-wrap{
            margin-bottom: 32px;
            .shipping-content{
                padding: 10px 15px;
                margin-top: 12px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background: #eaf5ff;
                border-radius: 2px;
                i{
                    font-size: 20px;
                    color: #1890ff;
                }
            }
        }
        .btn-group{
            display: flex;
            flex-direction: row;
            .el-button{
                width: 100%;
                height: 50px;
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Medium;
                &:not(:first-of-type) {
                    margin-left: 24px;
                }
                &.add{
                    background: #434343;
                }
            }
        }
    }
}
.shipping-free{
    .free{
        padding: 5px;
        border-bottom: 1px solid $borderColor;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.last {
            border-bottom-width: 0;
        }
        span{
            color: $mainPrimary;
            margin-left: 100px;
        }
    }
    .desc{
        padding: 10px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 23px;
    }
}
.fixed-product-info {
    position: fixed;
    top: $headerHeight;
    left: 0;
    right: 0;
    height: 150px;
    background: white;
    z-index: 22;
    padding: 10px 0;
    box-sizing: border-box;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    .fixed-product {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left-wrap {
            flex-grow: 0;
            width: 70%;
            .btn-group {
                margin-top: 20px;
                padding-left: 0;
            }
            .info-wrap {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .image {
                    width: 70px;
                    height: 70px;
                    border-radius: 2px;
                    margin-right: 20px;
                    // box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
                    overflow: hidden;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-size: 14px;
                        font-family: PingFangSC, PingFangSC-Medium;
                        font-weight: 500;
                        text-align: left;
                        color: #1a1a1a;
                        line-height: 20px;
                        @include ellipsis(2)
                    }
                    .star-number{
                        margin-top: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: $mainPrimary;
                        .el-rate{
                            margin-right: 15px;
                            height: auto;
                        }
                    }
                }
            }
        }
        .right-wrap{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .price-wrap {
                text-align: right;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                .title {
                    font-size: 18px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #000000;
                    line-height: 20px;
                    margin-right: 10px;
                }
                .price{
                    font-size: 24px;
                    font-family: PingFangSC, PingFangSC-Medium;
                }
            }
            .btn-group {
                text-align: right;
                margin-top: 13px;
            }
        }
    }
    /deep/ .el-radio-button__inner {
        font-size: 14px;
        font-family: Helvetica;
    }
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner{
        color: $mainPrimary;
        background-color: #fff2eb;
        border-color: $mainPrimary;
        box-shadow: -1px 0 0 0 $mainPrimary;
    }
}
</style>
