<template>
    <div class="variant-wrap border-top">
        <div class="anchor" id="VariantsAnchorId"></div>
        <div class="module-title">{{ title }}</div>
        <div class="table-list">
            <div class="rap-row header">
                <div class="rap-col radio-wrap"></div>
                <div class="rap-col" v-for="(column, index) in columns" :key="index">
                    {{ column.label }}
                </div>
            </div>
            <div class="rap-row" v-for="(item, index) in dataLists" :key="index">
                <div class="rap-col radio-wrap">
                    <img class="radio" :src="curSkuId == item.skuId ? select : unSelect"/>
                </div>
                <div class="rap-col" v-for="(column, index) in columns" :key="index">
                    <span v-if="column.label === 'Cost' && originCurrency">{{ originCurrency | filterCurrent }}</span>
                    {{ item[column.key] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['product', 'title'],
    data () {
        return {
            unSelect: require('@/assets/rap/product/radio-unselect.svg'),
            select: require('@/assets/rap/product/radio-select.svg')
        }
    },
    computed: {
        columns () {
            if (this.product.attrInfo) {
                const { attrGroup } = this.product.attrInfo
                const columns = [
                    { label: 'Sku', value: '', key: 'sellSku' }
                ]
                attrGroup.map(p => {
                    columns.push({ label: p.attrName, value: p.attrId, key: p.attrName.toLocaleLowerCase() })
                })
                if (columns.length) {
                    columns.push({ label: 'Cost', value: '', key: 'sellPriceString' })
                }
                return columns
            }
            return []
        },
        // 币种
        originCurrency () {
            return (this.product.skuDto && this.product.skuDto.originCurrency) || ''
        },
        dataLists () {
            if (this.product.attrInfo) {
                const { skuAttrGroup } = this.product.attrInfo
                const lists = []
                skuAttrGroup.map(sku => {
                    const item = {}
                    this.columns.map(col => {
                        if (!col.value && col.key) {
                            item[col.key] = sku[col.key]
                        } else if (col.value) {
                            const obj = (sku.skuAttrs || []).find(p => p.attrId === col.value)
                            if (obj) {
                                item[col.key] = obj.attrValue
                            }
                        }
                    })
                    item.skuId = sku.skuId
                    lists.push(item)
                })
                return lists
            }
            return []
        },
        curSkuId () {
            return (this.product.skuDto && this.product.skuDto.skuId)
        }
    }
}
</script>

<style lang="scss" scoped>
.variant-wrap{
    padding: 24px 24px 0 24px;
    position: relative;
    .table-list{
        margin: 24px 0 30px 0;
        .rap-row{
            display: flex;
            flex-direction: row;
            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
            &.header .rap-col {
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0,0,0,0.65);
            }
            .rap-col{
                flex-shrink: 0;
                padding: 5px 10px;
                display: flex;
                font-size: 18px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #1a1a1a;
                &:not(.radio-wrap) {
                    min-width: 14%;
                    margin: 0 10px;
                }
                &.radio-wrap{
                    margin: 0 5px;
                    width: 20px !important;
                    text-align: center;
                    .radio{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
</style>
